<template>
	<div>
		<v-main>
			<v-stepper v-model="appintment_step" >
				<v-stepper-header>
					<v-stepper-step :complete="appintment_step > 1" step="1">Филиал</v-stepper-step>
					<v-stepper-step :complete="appintment_step > 2" step="2">Дата приема</v-stepper-step>
					<v-stepper-step :complete="appintment_step > 3" step="3">Время приема</v-stepper-step>
					<v-stepper-step step="4">Цель посещения</v-stepper-step>
				</v-stepper-header>
				<v-divider></v-divider>
				<template v-if="record.org">
					Вы записываетесь в организацию <b>{{ getOrgName(record.org) }}</b> на {{ record.time }} {{ record.date_show }}
					<v-divider></v-divider>
				</template>
				<v-stepper-content step="1">
					<v-row><v-col class="col-12">Выберите филиал</v-col></v-row>
					<v-row>
						<v-col class="col-12 col-sm-6 col-md-4" v-for="org in orgs" :key="org.org_id">
							<v-card color="grey lighten-2" class="mb-12" height="200px" hover @click="nextStep(2,{org:org.org_id})">
								<v-card-title>{{ org.org_name_short }} </v-card-title>
								<v-card-text>{{ org.org_address }}</v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</v-stepper-content>
				<!-- Шаг 2 -->
				<v-stepper-content step="2">
					<v-row><v-col class="col-12">Выберите желаемую дату приема</v-col></v-row>
					<v-row>
						<v-col class="col-12 col-sm-6 col-md-3 py-auto">
							<v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">Сегодня</v-btn>
							<v-btn fab text small color="grey darken-2" @click="prev"><v-icon>mdi-chevron-left</v-icon></v-btn>
							<v-btn fab text small color="grey darken-2" @click="next"><v-icon>mdi-chevron-right</v-icon></v-btn>
						</v-col>
						<v-col class="col-12 col-sm-4 col-md-3 py-auto">
							<v-toolbar-title v-if="$refs.calendar">{{ $refs.calendar.title }}</v-toolbar-title>
						</v-col>
						<!-- Выбор сотрудника -->
						<v-col class="col-12 col-sm-12 col-md-6 py-0 ma-auto">
							<v-select v-model="staff" :items='staffs' item-text="name" label='Сотрудник' chips multiple persistent-hint return-object></v-select>
						</v-col>
						<!-- Выбори специализации -->
						<v-col class="col-12 ma-0 pa-auto">
							<v-autocomplete v-model="employee_specialization" :items='employee_specializations' item-text="name" label='Специализация' chips multiple persistent-hint return-object></v-autocomplete>
						</v-col>
						<!-- Календарь -->
						<v-col class="col-12">
							<v-sheet height="600">
								<v-calendar v-model="focus" ref="calendar" :now="today" :value="today" :events="events" color="primary" type="month" :weekdays="weekday" @click:event="selectEvent" @click:date="selectDate"></v-calendar>
							</v-sheet>
						</v-col>
					</v-row>
					<!--
					<v-row>
						<v-col class="col-12 col-md-4">
							<v-menu>
								<template v-slot:activator="{ on }">
									<v-text-field v-model="record.date" v-on="on" outlined label="Дата записи" readonly  return-object required :rules="[v=>!!v || 'Выберете желаемую дату записи']" ></v-text-field>
								</template>
								<v-date-picker first-day-of-week="1" locale="ru-ru" v-model="record.date" @input="fromDateMenu = false" :allowed-dates="allowedDates" :show-current="true" >
								</v-date-picker>
							</v-menu>
						</v-col>
					</v-row>
					-->
					<v-btn text @click="nextStep(1);record={}">Назад</v-btn>
					<v-btn color="primary" @click="nextStep(3, {org:record.org, date:record.date})">Далее</v-btn>
				</v-stepper-content>
				<!-- Шаг 3 -->
				<v-stepper-content step="3">
					<v-row><v-col class="col-12">Выберите время</v-col></v-row>
					<v-row>
						<v-col class="col-lg-1 col-md-2 col-sm-2 col-4 ma-2" v-for="(time, index) in times" :key="index">
							<v-btn class="pa-5" color="green accent-2" :disabled="!time.allow" @click="nextStep(4, {org:record.org, date:record.date, time:time.time})">{{ time.time }}</v-btn> 
						</v-col>
					</v-row>
					<v-btn text @click="nextStep(2, {org:record.org, date:record.date})">Назад</v-btn>
					<v-btn color="primary" @click="appintment_step = 4">Далее</v-btn>
				</v-stepper-content>
				<!-- Шаг 4 -->
				<v-stepper-content step="4">
					<v-row><v-col class="col-12">Опишите цель посещения</v-col></v-row>
					<v-row>
						<v-col class="col-12 col-sm-6">
							<v-text-field v-model="record.phone" placeholder='+7 (999) 999-99-99' outlined @keydown="checkPhone" @keyup='makeMask' type="phone"></v-text-field>

							<!--
							<imask-input v-model="record.phone" mask='+{7} (000) 000-00-00' placeholder='+7 (999) 999-99-99' @accept:masked="onAccept"></imask-input>
							
							
							<input id="phone" type="text" class="form-control bfh-phone" placeholder="+7 (999) 999-99-99" v-model="phone" autocomplete="off" required="" >

							<vue-tel-input-vuetify v-model="record.phone" 
								:onlyCountries="county" :maxlength='maxLen'
								@keydown="checkPhone" outlined required
								:rules="[v=>!!v || 'Введите телефон']"></vue-tel-input-vuetify>
							-->
						</v-col>
						<v-col class="col-12 col-sm-6">
							<v-select label="Вид животного" :items="animalTypes" v-model="record.animalType" outlined></v-select>
						</v-col>
					</v-row>
					<v-row><v-col><v-textarea outlined label="Опишите причину обращения" required :rules="[v => !!v || 'Опишите причину обращения']" v-model="record.reasonForPerson"></v-textarea></v-col></v-row>
					<v-row></v-row>
					<!-- После записи, пропадает часть данных, а точнее staff и staff_id -->
					<v-btn text @click="nextStep(3, {org:record.org, date:record.date, staff_id:record.staff.staff_id})">Назад</v-btn>
					<v-btn color="primary" @click="sendAppointment">Записаться на прием</v-btn>
				</v-stepper-content>
			</v-stepper>
		</v-main>

		<v-dialog v-model="dialog" width="500" >
			<v-card :loading="appointmentLoad">
				<template slot="progress">
					<v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
				</template>

				<v-card-title class="text-h5 green lighten-2" v-if="appointmentStatus">Запись на прием</v-card-title>
				<v-card-title class="text-h5 red lighten-2" v-else>! ОШИБКА ЗАПИСИ !</v-card-title>
				<v-card-text>{{ message }}<br>
				<hr v-if="appointmentStatus">
				<p v-if="appointmentStatus">Вы записались в организацию {{ getOrgName(record.org) }} на {{ record.time }} {{ record.date_show }}</p>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="dialog=false; nextStep(1);record={}" v-if="appointmentStatus">ОК</v-btn>
					<v-btn color="primary" text @click="dialog=false" v-else>Назад</v-btn>
					<!--
						<v-btn color="primary" text @click="dialog=false; nextStep(3, {org:record.org, date:record.date})" v-else>Назад</v-btn>
					-->
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>


<script type="text/javascript">




function timesNextInterval(start_time, ad_interval){
	//console.log('timesNextInterval:start_time:', start_time, start_time.getTime())
	var newTime = new Date(start_time.getTime() + 60*1000 * ad_interval)
	return newTime
}

//import moment from 'moment'
var moment = require('moment');

// С данной строкой связана проблема с тем, что модуль подгружается в самом начале, т.е. какая страница была первой - такая и будет затем открываться
//const url = new URL(window.location);
//console.log("AppointmentComponent->INIT URL:", url)

export default {
	name:'ComponentAppointment',
	components: {},
	data () {
		return {
			county: ['RU',],
			maxLen: 4,
			record: {},
			appintment_step: 1,
			dialog:false,
			today: moment().format('YYYY-MM-DD'),
			focus: '',
			weekday: [1,2,3,4,5,6,0],
			animalTypes: [
				'Кошка',
				'Собака',
				'Грызуны',
				'Хорек',
				'Птица',
				'Рептилия',
				'Пресмыкающиеся',
				'Рыба',
				'Другое',
			],
			employee_specialization: [],
			// Настройка прав пользователя
			employee_specializations: [
				{name: 'Первичный прием',	ids:[12,17,1,2,5]},
				{name: 'Общая хирургия', 	ids:[12,1,2]},
				{name: 'Неврология',		ids:[1,2]},
				{name: 'Офтальмология',		ids:[1,2]},
				{name: 'Дерматология', 		ids:[17,2,5]},
				{name: 'Микрохирургия', 	ids:[1,2]},
				{name: 'Ортопедия',			ids:[1]},
				{name: 'Предоперационный скриниг сердца', ids:[17,2,5]},
				{name: 'Нефрология', 		ids:[12,17,2]},
				{name: 'Эндокринолог',		ids:[2]},
				{name: 'Травматолог',		ids:[1]},
				{name: 'Кардиология',		ids:[2]},
			],
			staff: [],
			staffs: [
				{id:12, name: 'Ненашева М.С.',	fullname:'Ненашева Маргарита Сергеевна',	color:'#F9A825'},
				{id:17, name: 'Попова С.А.',	fullname:'Попова Светлана Александровна',	color:'red'},
				{id:1,  name: 'Чистов М.С.',	fullname:'Чистов Максим Сергеевич',			color:'cyan'},
				{id:2,  name: 'Чистова Е.С.',	fullname:'Чистова Екатерина Сергеевна',		color:'#8E24AA'},
				{id:5,  name: 'Давидянц Э.А.',	fullname:'Давидянц Эвелина Арсеновна',		color:'#64B5F6'},
			],
		}
	},
	methods: {
		onAccept(value) {console.log("ACCESS", value)},
		setToday ()	{ 
			// При нажатии на кнопку, выбирается автоматически сегодняшний день.
			console.log("AppointmentComponent->SelectToday")
			this.focus = ''
			var date = moment().format('YYYY-MM-DD')
			this.selectDate({date:date})
		},
		prev () 	{ this.$refs.calendar.prev() },
		next () 	{ this.$refs.calendar.next() },
		//selectEvent({ nativeEvent, event }) {
		selectDate({date}) {
			console.log('AppointmentComponent->selectDate', date)				// 2022-03-17
			this.record.date = date
			this.record.date_show = moment(this.record.date).format('DD.MM.YYYY')
			// Получаем сотрудника, который главный в выбранный день
			this.record.staff = this.schedule.find((obj)=>( obj.main==true && obj.day==this.record.date_show ))
			console.log('AppointmentComponent->this.record.staff:', this.record.staff)
			// Если пользователь не выбран - не даем возможность выбрать день
			if (this.record.staff){
				//this.record.staff = event
				this.nextStep(3, {org:this.record.org, date:this.record.date})
			}
		},
		selectEvent({event}) {
			console.log('AppointmentComponent->selectEvent', event)
			// 3, {org:record.org, date:record.date}
			if (event.main) {
				this.record.date = event.day 			// 2022-03-14
				this.record.date_show = moment(this.record.date).format('DD.MM.YYYY')
				this.record.staff = event
				this.nextStep(3, {org:this.record.org, date:this.record.date})
			}
		},
		/*
		getDate_fromURL(newdate=null){
			// Устанавливаем дату в сроку поиска
			const urlParams = new URLSearchParams(window.location.search);
			if (newdate) {
				// Добавляем в URL дату
				const url = new URL(window.location);
				//console.log("SET NEW DATE:", newdate)
				url.searchParams.set('date', newdate)
				//console.log(urlParams.toString())
				//console.log('URL:', url)
				this.record.date = newdate
			} else {
				this.record.date = urlParams.get('date');
			}
			//console.log(this.record.date)
			this.$store.dispatch('uploadOrgsWorkTimesAsync', {org:this.record.org, date:this.formatDate(this.record.date)})
		},
		*/
		nextStep(step, val=null){
			// Устанавливаем параметры для организации и времени
			const url = new URL(window.location);
			// Получаем (window.location.search);
			console.log('AppointmentComponent->nextStep router:', this.$router)
			console.log('AppointmentComponent->nextStep: step:', step, 'val:', val)
			//const url = new URL(window.location);
			// Стераем поисковую строку, и наполняем ее заново
			console.log("AppointmentComponent->nextStep->URL:", url.search)
			//url.search = ''
			if (val && val.org){ 
				console.log('AppointmentComponent->nextStep:org:', val.org)
				this.record.org = val.org;   
				url.searchParams.set('org', val.org);
				this.$store.dispatch('uploadOrgsWorkDaysAsync', val.org);
			}
			if (val && val.date){ 
				console.log('AppointmentComponent->nextStep:date:', val.date)
				this.record.date = val.date;
				this.record.date_show = moment(this.record.date).format('DD.MM.YYYY')
				url.searchParams.set('date', val.date);
				//this.getDate_fromURL(val.date)
				console.log('AppointmentComponent->nextStep->uploadOrgsWorkTimesAsync:')
				this.$store.dispatch('uploadOrgsWorkTimesAsync', {org:this.record.org, date:this.formatDate(this.record.date)})
				console.log('AppointmentComponent->nextStep->date:', val.date)
			}
			if (val && val.time){ 
				console.log('nextStep:time:', val.time)
				this.record.time= val.time; 
				url.searchParams.set('time', val.time);  
			}
			console.log("VAL:", val)
			if (this.record.staff) {
				//this.record.staff = val.staff
				//console.log('this.record.staff', this.record.staff.staff_id)
				// Добавляем ID пользователя, для записи непосредственно к нему
				url.searchParams.set('staff_id', this.record.staff.staff_id)

			}
			this.appintment_step = step
			// Устанавливаем параметр step
			console.log("AppointmentComponent->nextStep->URL:", url.search)
			url.searchParams.set('step', this.appintment_step)
			//window.location.search = urlParams
			// Обновляем URL
			//console.log('nextStpe url to history:', url)
			//console.log('nextStpe history.length:', history.length)
			//console.log('nextStpe history.state:', history.state)
			console.log("AppointmentComponent->nextStep->URL:", url.search)
			window.history.pushState({}, '', url)
			//window.history.pushState(val, '', url)
			//this.$router.push('/')
			//console.log('window.location.hash:', window.location.lasthash, '-', window.location.hash)
		},
		checkValidPhone() {
			console.log('checkValidPhone')
		},
		sendAppointment(){
			// Отправляем данные на сервер
			const url = new URL(window.location); // Получаем url
			//console.log("sendAppointment")
			this.dialog = true
			//Получаем сотрудника, если он отсутствет в переданных параметрах сессии и проверяем на доступность записи к данному сотруднику
			if (! this.record.staff && url.searchParams.get('staff_id')) {
				var staff_id = url.searchParams.get('staff_id')
				console.log('AppointmentComponent->sendAppointment NO STAFF and get from staff_id', staff_id)
				// Получаем данные о пользователе из событий, т.е. графика работы
				var user_info = this.events.find((obj)=>(obj.staff_id==staff_id && obj.day==this.record.date))
				console.log('AppointmentComponent->sendAppointment FIND USER INFO', user_info)
				console.log('AppointmentComponent->sendAppointment this.record', this.record.staff)
				this.record.staff = user_info
				console.log('AppointmentComponent->sendAppointment this.record', this.record.staff)
			} 
			//console.log('this.record.staff:', url.searchParams.get('staff_id'), '->', this.record.staff)
			//console.log('validate:', this.record.phone.length, this.record.phone)
			// Если длинна номера= 17 - значит номер указан верно
			if (this.record.phone && this.record.phone.length == 18 && this.record.animalType && this.record.reasonForPerson && this.record.staff) {
				console.log("AppointmentComponent->sendAppointment->Send data:", this.record)
				console.log("AppointmentComponent->sendAppointment->Send data (staff):", this.record.staff)
				// Сохраняем данные пользователя, что бы потом его восстановить
				var tmp_staff = this.record.staff
				this.message = 'Отправлен запрос на запись'
				this.$store.commit('uploadAppointment', this.record)
				// Восстанавливаем пользователя
				this.record.staff = tmp_staff
				console.log("AppointmentComponent->sendAppointment->Send data (staff) over uploadAppointment:", this.record.staff)
			} else {
				console.log('Ошибка в заполнении полей', this.record.phone, this.record.animalType, this.record.reasonForPerson, this.record.staff)
				var mess = 'Заполните пожалуйста все поля: '
				if (!this.record.phone) { mess += ' (Ошибка в заполении телефона); '}
				if (!this.record.animalType) { mess += 'Не указан "Вид животного"; '}
				if (!this.record.reasonForPerson) { mess += 'Не указана "Причина обращения"; '}
				if (!this.record.staff) {mess += 'Не выбран сотрудник. Рекомендуется начать с начала)'}
				this.message = mess
			}
			//console.log("sendAppointment:message:", this.message)
		},
		makeTimes(){
			// Формируем список доступного времени
			console.log('makeTimes:', this.times)
		},
		getOrgName(org){
			// Получаем название организации по номеру
			var obj = this.orgs.find(function(val) { return val.org_id==org })
			if (obj) {
				return obj.org_name_short
			} else {
				return ''
			}
		},
		allowedDates(val){
			// Определяем какие дни показывать в каледаре
			// Получаем сегодняшний день, т.к. запись на предыдущие дни невозможна
			var today = new Date( new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
			// VAL FORMAT: 2022-02-28 - переделываем формат в нам понятный
			var day = new Date(val.split('-')[0], val.split('-')[1]-1, val.split('-')[2])
			// Заполняем или получаем разрешенные дни. Если массив пустой - значит все разрешено
			//console.log('val', val, "-> DAY:", day, "TODAY:", today, "compare:", day>=today)
			if (day >= today) {
				// Если день в календаре day больше или равен сегодня - значит показываем				
				// Проходим по расписанию и проверяем рабочие дни
				for (var i in this.schedule) {
					const [tmp_day, tmp_month, tmp_year] = this.schedule[i].day.split('.')
					const compare_day = new Date(tmp_year, tmp_month-1, tmp_day)
					if (compare_day.toJSON()==day.toJSON()) {
						return true
					}
				}
			}
			return false
		},
		updateRange ({ start, end }) {
			console.log('updateRange', start, '-', end)
			if (this.record.org) {
				// Если указали организацию - будем показывать докторов, которые работают в этой организации
				this.doctors = this.record.org.staff
			}
			if (this.record.doctor){
				this.allowedDatesList = this.record.doctor.workdays
				//console.log('doctor', this.allowedDatesList)
				//this.record.date = null
			}
		},
		makeMask() {
			//console.log("MakeMask:", value, "(", value.ctrlKey, ")")
			//if (!value.key.match('[0-9-()+]')) {
			//	this.record.phone = this.record.phone.slice(0,-1)
			//}
			if (this.record.phone){
				//console.log("Есть телефон:", this.record.phone.length, this.record.phone, value.key)
				// Проверяем корректность номера. Т.е. убираем все, кроме + и цифр
				// 8(963)782-5491
				//console.log('Старый номер', this.record.phone)
				var newPhone = this.record.phone.replace(/[^+\d]/g, '').replace(' ','')
				//console.log("Новый номер:", newPhone)
				// Если первый символ 8 или 7 (без +) - меняем его на +7
				if (newPhone.startsWith('8') || newPhone.startsWith('7')){
					newPhone = "+7" + newPhone.substr(1, newPhone.length)
				}
				if (!newPhone.startsWith('+7')){
					newPhone = "+7" + newPhone
				}
				// Удаляем + если есть в самом номере
				newPhone = "+"+newPhone.substr(1, newPhone.length).replace('+','')
				// Преобразуем номер в красивый формат
				var phonePref = newPhone.substr(2,3)
				var phoneOct1 = newPhone.substr(5,3)
				var phoneOct2 = newPhone.substr(8,2)
				var phoneOct3 = newPhone.substr(10,2)
				//console.log('phonePref: (', phonePref, ') ', phoneOct1, "-", phoneOct2, "-", phoneOct3)
				//console.log("Новый номер:", newPhone)
				// Формурем красивый номер в выводе
				this.record.phone = '+7 ('+phonePref
				if (phonePref.length == 3) { this.record.phone += ') ' }
				if (phoneOct1){ this.record.phone += phoneOct1 }
				if (phoneOct2){ this.record.phone += "-"+phoneOct2 }
				if (phoneOct3){ this.record.phone += "-"+phoneOct3 }
				// '+ phoneOct1+ "-" + phoneOct2 + "-" + phoneOct3
				/*
				if (newPhone.startsWith('+7') && newPhone.length == 12) {
					console.log("НОМЕР КОРРЕКТНЫЙ")
					//this.record.phone = newPhone
				}
				*/
			}
		},
		checkPhone(value) {
			//console.log('checkPhone:', value)
			//var oldVal = ''
			//console.log(this.phone.length)
			if (value.key == 'Backspace' || value.key == 'Delete' || value.key == 'Tab' || value.key == 'Enter' || value.key == 'ArrowLeft' || value.key == 'ArrowRight') { return }
			// Отрабатываем вставку сочетанием Ctrl+V
			if ((value.key == 'v' && value.ctrlKey) || (value.key =='Insert' && value.shiftKey ) || (value.key == 'м' && value.ctrlKey)){ return }
			if (this.record.phone && this.record.phone.length > 17){
				//console.log("Max length")
				// Если превышаем длинну - ничего не возвращаем
				value.preventDefault()
			}
			if (!value.key.match('[0-9-()+]')) {
				// Если не разрешенные символы - ничего не возвращаем
				value.preventDefault()
			}
			//console.log(pattern.test(value))
		},
		formatDate(date) {
			// Преобразуем дату в другой формат
			if (!date) return null
			const [year, month, day] = date.split('-')
			var newdate = `${day}.${month}.${year}`
			//console.log('date', date, '->', newdate)
			return newdate
		},
		getColorEvent(obj) {
			// Возвращаем цвет в зависимости от имени пользователя
			//var name = obj.staff_name
			var sorted = this.staffs.filter((staf)=>(staf.fullname==obj.staff_name))
			//console.log("L:", sorted.length)
			if (sorted.length<=0){
				return "green"
			}
			// Возвращаем цвет
			return sorted[0].color
		},
	},
	computed: {
		orgs() { return this.$store.getters.getOrgs },
		schedule() { return this.$store.getters.getSchedule },
		scheduletime() { return this.$store.getters.getScheduleTime },
		message: { 
			// Управляем сообщением в диалоговом окне
			//cache: false,
			get: function()			{ return this.$store.getters.getAppointmentMessage },
			set: function(value) 	{ this.$store.commit('setAppointmentMessage', value)}
		},
		appointmentStatus() { return this.$store.getters.getAppointmentStatus },
		appointmentLoad() { return this.$store.getters.getAppointmentLoad },
		events() {
			// Формируем события
			var result = []
			//console.log('AppointmentComponent->events this.schedule', this.schedule.filter((obj)=>(obj.main==true)))
			for (var i in this.schedule) {
				// Проходим по записям и формируем события
				var schedule = this.schedule[i]
				//console.log(schedule)
				// Получаем день из формата dd.mm.yyyy
				var day = moment(schedule.day, 'DD.MM.YYYY')
				// Формируем начало записи
				var start_day = day.format('YYYY-MM-DD') + " "+schedule.begin_of_day
				var end_day   = day.format('YYYY-MM-DD') + " "+schedule.end_of_day
				if (! this.staff.length>0 || this.staff.find(obj=>(obj.fullname == schedule.staff_name))){
					// Если указан не выбрал ни один сотрудник или сотрудник есть в выбранных - будем показывать в событиях
					//console.log(schedule)
					// Проверяем employee_specializations - специализации. Если выбрана специализация - фильтруем по ней
					//console.log("employee_specialization:", this.employee_specialization)
					if (! this.employee_specialization.length>0  || this.employee_specialization.find(obj=>(obj.ids.includes(schedule.staff_id)))){
						// Добавляем событие в календарь
						result.push({
							name:schedule.staff_name, 
							start:start_day, 
							end:end_day, 
							color:this.getColorEvent(schedule),
							org_id:schedule.org_id,
							staff_id: schedule.staff_id,
							day: moment(schedule.day, 'DD.MM.YYYY').format('YYYY-MM-DD'), 
							main: schedule.main,
						})
					}
				}
			}
			return result
		},
		
		times() {
			// Функция вывода временных интервалов сотрудника
			// {time:'09:00', allow:true},
			// begin_of_day, end_of_day
			// org_business_hours_start: "10:00"
			// org_business_hours_stop: "20:00"
			// org_admission_interval: 30
			// Если record.org - не указан - ничего не выводим
			//console.log('AppointmentComponent->times->record:', this.record, this.orgs)
			const url = new URL(window.location); // Получаем url
			// Получаем доступное время для сотрудника
			var staff_time = this.$store.getters.getScheduleTime
			console.log('AppointmentComponent->times->staff_time: Знятое время сотрудника', staff_time)
			// Если не указана организация или список организаций пустой - выходим
			if (!this.record.org || this.orgs.length==0) { console.log('AppointmentComponent->times-> EXIT'); return null }
			// Получаем дату записи
			//console.log("TIMES:", this.record)
			const compare_day_tmp = new Date(this.record.date)
			console.log('AppointmentComponent->times->compare_day_tmp:', moment(compare_day_tmp).format('DD.MM.YYYY'))
			// Получаем нужный день, что бы получить от туда данные по началу и окончанию рабочего дня
			var tmp_org = this.record.org
			var org = this.orgs.find(function(a){ return a.org_id == tmp_org})
			// Формирумем массив времени от org_business_hours_start до org_business_hours_stop с шагом org_admission_interval минут
			//console.log('org_business_hours_start:', tmp_org, org, this.orgs.length)
			const [ st_hour, st_minutes ] = org.org_business_hours_start.split(':')
			//const [ et_hour, et_minutes ] = org.org_business_hours_stop.split(":")
			// Получаем schedule сотрудника, путем фильтрации из общего списка сотрудников
			var staff_obj = this.schedule.find((el)=>{if (el.staff_id==url.searchParams.get('staff_id') && el.day==moment(compare_day_tmp).format("DD.MM.yyyy")){ return el }})
			var et_hour = 0
			var et_minutes = 0
			if (staff_obj){
				[ et_hour, et_minutes ] = staff_obj.end_of_day.split(':')
			} else {
				console.log("staff_obj NOT FOUND")
			}
			
			// Получаем интервалы с которыми ведется прием в клинике
			const ad_interval = org.org_admission_interval
			// Формиурем начало и окончание рабочего дня
			var start_time = new Date(compare_day_tmp.getFullYear(), compare_day_tmp.getMonth(), compare_day_tmp.getDate(),st_hour,st_minutes)
			var end_time   = new Date(compare_day_tmp.getFullYear(), compare_day_tmp.getMonth(), compare_day_tmp.getDate(),et_hour,et_minutes)
			// Формируем список дат
			var dates = []
			console.log("AppointmentComponent->times->staart_time:", start_time, "end_time:", end_time)
			while (start_time.toJSON()<end_time.toJSON()) {
				// Проверяем интервал работы сотрудника и если он меньше чем следующий интервал - завершаем массив
				//var date = moment(start_time).format("DD.MM.YYYY")
				// Если цикл не прервали - значит добавляем данные в dates
				dates.push({
					date:moment(start_time).format("DD.MM.YYYY"),
					time:moment(start_time).format("HH:mm"), 
					//allow: tt_info ? false : true,		// Если запись есть - значит нельзя записаться
					allow: false,
					info: null,
				})	// Добавляем в массив дату
				//console.log("AppointmentComponent->times->add time:", moment(start_time).format('DD.MM.YYYY'))
				// Переходим к следующему интервалу
				start_time = timesNextInterval(start_time, ad_interval)	// Прибавляем интервал и формируем следующий
				
			}
			console.log("AppointmentComponent->times->Получили список доступного времени для записи", dates)
			//console.log('AppointmentComponent->times->start_time:', moment(start_time).format('DD.MM.YYYY HH:mm'), 'end_time:', moment(end_time).format('DD.MM.YYYY HH:mm'))
			// Проходим по всем записям от начала до завершения дня
			for (var index in dates){
				var rec = dates[index]
				var today = moment()
				var todayTime = moment().format('HH:mm')
				// Получаем время
				var tt_info = staff_time.find(function(a){ return a.appointment_appointment_time == rec.time })
				//console.log("AppointmentComponent->times->rec:", rec)
				if (tt_info){
					//console.log("AppointmentComponent->times->rec and tt_info:", tt_info)
					rec.info = tt_info
				} else {
					// Если дата меньше сегодняшней - скрываем записи
					// Если сегодня - обрезаем время
					if ( moment(rec.date, 'DD.MM.YYYY') > today){
						console.log("AppointmentComponent->times->rec and NOT tt_info and select day>today:", moment(rec.date, 'DD.MM.YYYY').format() > today)
						rec.allow = true
					} else if (rec.date == today.format('DD.MM.YYYY')) {
						// Если сегодняшний день - проверяем время
						
						console.log("AppointmentComponent->times->todayTime ("+todayTime+") rec.time ("+rec.time+") <=",   todayTime <= rec.time)
						if (todayTime <= rec.time) { rec.allow = true }
					} else {
						console.log("AppointmentComponent->times->rec and NOT tt_info OTHER:", moment(rec.date, 'DD.MM.YYYY').format(), today.format())
					}
				}
			}
			return dates			
		}
	},
	beforeMount() {
		// Получаем данные из параметров GET запроса и подставляем уже заполненные
		//const urlParams = new URLSearchParams(window.location.search);
		const url = new URL(window.location); // Получаем url
		this.$store.dispatch('uploadAppointmentOrgInfoAsync')
		this.$store.dispatch('uploadOrgsAsync')
		//this.appintment_step=1
		// Получаем шаг
		
		this.appintment_step = url.searchParams.get('step')
		if (! this.appintment_step) {
			this.appintment_step = 1
		}
		//console.log('STEP:', this.appintment_step)
		// Получаем организацию
		console.log('U:', url.searchParams.get('org'))
		if (url.searchParams.get('org')) { 
			this.record.org = url.searchParams.get('org');		
			this.appintment_step=2; 
			this.$store.dispatch('uploadOrgsWorkDaysAsync', this.record.org)
			//console.log("ffff", this.schedule )
		}
		// Получаем дату
		if (url.searchParams.get('date')) { 
			//this.getDate_fromURL()
			this.record.date = url.searchParams.get('date');
			this.record.date_show = moment(this.record.date).format('DD.MM.YYYY')
			this.$store.dispatch('uploadOrgsWorkTimesAsync', {org:this.record.org, date:this.formatDate(this.record.date)})
			this.appintment_step=3 
		}
		// Получаем время
		if (url.searchParams.get('time')) { this.record.time = url.searchParams.get('time');	this.appintment_step=4 }
		// Получаем телефон "8 (963) 782-54-19"
		if (url.searchParams.get('phone')) { this.record.phone = url.searchParams.get('phone');}
		// Если передали сотрудника, то нужно получить его данные и проверить работает ли он в этот день
		if (url.searchParams.get('staff_id')) {
			console.log('Перезапись staff_id')
			//this.record.staff = {staff_id: Number(url.searchParams.get('staff_id'))}
			// Требуется получить информацию о сотруднике
			//console.log('-', staff_id, this.record.date)
			console.log('this.record.staff:', this.record.staff)
			console.log('events:', this.events)
			//console.log('staff:', this.events.find((obj)=>(console.log('asa', obj.day))))
		}
	},
}

</script>