<template>
	<div>
		<AppointmentComponent></AppointmentComponent>
	</div>
</template>

<script type="text/javascript">

import AppointmentComponent from '@/components/AppointmentComponent'

export default {
	name:'Staff',
	components: {
		AppointmentComponent,
	}
}
</script>